@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import './app/variables.scss';
// MAT CARD STYLE
//@import "./assets/styles/angular/material/mat-card.scss";
@import '~@angular/cdk/overlay-prebuilt.css';
/* You can add global styles to this file, and also import other style files */


.tooltip-free-bet-color {
	background: $miloto-secondary;
	font-size: 12px;
}

.especial-crisp {
	background-color: $miloto-cool !important;
}

.crisp-client .cc-kv6t .cc-1ada,
.crisp-client .cc-kv6t .cc-ew5j:before,
.crisp-client .cc-kv6t .cc-ew5j:after {
	background-color: $miloto-primary !important;
}


body {
	margin: 0;
	padding: 0;
	width: 100dvw;
	height: 100dvh;
	overflow: hidden;
}

.grecaptcha-badge { visibility: hidden; }

//* { outline: 1px solid red; }

* {
	font-family: $font-primary;
	box-sizing: border-box;
	touch-action: manipulation;
	scrollbar-width: thin;
}

.box-success,
.box-warning,
.box-error {
	position: absolute;
	width: 77%;
	bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 45px 24px 20px;
	border-radius: 16px;
	font-size: 13px;
	line-height: 20px;
	font-weight: 400;
}

.box-success {
	background: #F0FFFB;
	border: 1px solid #00BA88;
	color: #00BA88;
}

.box-warning {
	background: #ffeede;
	border: 1px solid #ffa048;
	color: #ffa048;
}

.box-error {
	background: #FFEAF1;
	border: 1px solid #C10045;
	color: #FF3880;
}

.dialog-amount>mat-dialog-container {

	background-color: rgb(228, 59, 59) !important;
	color: white;

	}

	.dialog-play>mat-dialog-container {

		padding: 0;

	}

.dialog-play>mat-dialog-container {

	padding: 0;

}

.grecaptcha-badge { visibility: hidden; }

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.790) !important;
}

.swiper-container {
	height: auto;

	.swiper-wrapper {
		height: auto !important;

		.swiper-slide {
			display: flex;
			justify-content: center;

		}
	}
}

:root {
	--swiper-navigation-size: 15px !important;
}

.mat-bottom-sheet-container {
	padding: 0px !important;
}

.bottomSheetcontainer {
	padding: 0px;
	border-radius: 10px 10px 0px 0px !important;
	min-height: 89vh;
	overflow: hidden !important;


	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
}

.bottomSheetContainerJugadaRegalada {
	min-height: 50vh;
	overflow: hidden !important;
}

.bottomSheetContainerAceptarJugada {
	min-height: 30vh !important;
	overflow: hidden !important;
}

.bottomSheetContainerAceptarJugadaLogged {
	min-height: 20vh !important;
	overflow: hidden !important;
}


.backdropBottomSheet {
	background-color: transparent !important;
}

.share-modal .mat-mdc-dialog-container {
	width: 40vW;
	border-radius: 24px;
	padding: 0px;
}


.currency {
	font-size: 36px;
	line-height: 44px;
	font-weight: 600;
	color: $miloto-content1;
	margin: 0px;
}

.grecaptcha-badge {
	bottom: 90px !important;
	left: 0px !important;
}

// Arregla Animacion Spinner
.mat-progress-spinner-indeterminate-animation {
	/* keep anim slow to avoid wobble from rounding errors */
	animation-duration: 9s !important;
}

.mat-progress-spinner-indeterminate-animation circle {
	animation-name: progress-spinner-stroke-rotate !important;
	animation-timing-function: linear !important;
	animation-duration: 4s !important;
}

@keyframes progress-spinner-stroke-rotate {
	$start: 200%;
	/* approx 2/3 of circle circumference */
	$end: 20%;

	0% {
		stroke-dashoffset: $start;
	}

	50% {
		stroke-dashoffset: $end;
	}

	/* removing transform: rotate(720deg) causes wobble */
	100% {
		stroke-dashoffset: $start;
		transform: rotate(720deg);
	}
}