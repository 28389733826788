@import './../assets/styles/fonts/fonts.scss';
/* Miloto V2 Colors */
// Paleta Usuario
$miloto-primary: #1FC9FF;
$miloto-primary-dark: #007AB6;
$miloto-primary-light:#9EE8FF;
$miloto-light: #54D6FF;
$miloto-primary-soft: #F3FBFF;

// Paleta Grupos
$miloto-secondary: #FF3880;
$miloto-secondary-dark: #C10045;
$miloto-secondary-light: #FFA5C5;
$miloto-secondary-soft: #FFEAF1;

// Neutral
$miloto-content1: #000000;
$miloto-content2: #495057;
$miloto-content3: #9FA5AA;

$miloto-disabled: #CACFD2;
$miloto-borders: #E7EBED;
//$miloto-bg-light: #E7EBED;
$miloto-bg-light: #F7F8FA;
$miloto-bg-white: #FEFEFE;
$miloto-bg: #f9f7f9;

// System Colors
$miloto-intense: #FF1F1F;
$miloto-positive: #00BA88;
$miloto-warm: #FFBA35;
$miloto-cool: #7545FF;
$miloto-green: #00BA88;

/* Miloto V1 Colors */

/* Colors */
$color-primary: #4287f5;
$color-secondary: #b1cefb;
$color-success: #42f578;
$color-warning: #f5cb42;
$color-error: #f54242;
$color-danger: #f54242;
$color-white: #ffffff;
$color-black: #1e1e24;
$color-grey: #bdbdbd;
$color-light-grey: #e0e0e0;
$color-social-facebook-primary: #4b70ab;
$color-social-facebook-secondary: #395583;
$color-social-google-primary: #3f85f4;
$color-social-google-secondary: #2a579e;
$color-euromillones: #4287f5;
$color-bonoloto: #c92e2e;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;
$miloto-primary-old: #4882ff;
$miloto-primary-light: #b4ccff;
$miloto-success: #48ff66;
$miloto-success-light: #b4ffd1;
$miloto-warning: #ffa048;
$miloto-warning-light: #ffd8b4;

/* Custom heights for scrolling */
$height-main: calc(100% - 25px);

/* Box shadows */
$shadow-light-grey: 0 1px 5px 2px $color-light-grey;
$shadow-sections-outline: 0 0 5px 1px $color-light-grey;

/* Fonts */
$font-primary: 'Poppins';

/* Transitions */
$transition-button: background-color .2s, color .2s;

/*Extra Variable*/
$radius: 4px;

/*Button colors*/
$btn-primary: #84abff;
$btn-secondary: #3978ff;
$btn-red: #b62e2e;

/*Button colors*/
$btn-primary: #84abff;
$btn-primary-light: #b4ccff;
$btn-secondary: #4882ff;

$btn-pink: #FF3880;

/*Background colors*/
$bg-extra-stars: #ffd94b;

/* Transitions */
$btn-transition: background-color .5s, color .5s;