@font-face {
  font-family: 'Poppins';
  src: url('./Poppins/Bold/Poppins-Bold.woff2') format('woff2'),
      url('./Poppins/Bold/Poppins-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins/Light/Poppins-Light.woff2') format('woff2'),
      url('./Poppins/Light/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins//Black/Poppins-Black.woff2') format('woff2'),
      url('./Poppins/Black/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins/Thin/Poppins-Thin.woff2') format('woff2'),
      url('./Poppins/Thin/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins/Regular/Poppins-Regular.woff2') format('woff2'),
      url('./Poppins/Regular/Poppins-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Lottery';
	src: url('./lottery-numbers.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: normal;
  }