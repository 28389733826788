@use '@angular/material' as mat;

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-miloto-primary: (
	50 : #e4f9ff,
	100 : #bcefff,
	200 : #8fe4ff,
	300 : #62d9ff,
	400 : #41d1ff,
	500 : #1fc9ff,
	600 : #1bc3ff,
	700 : #17bcff,
	800 : #12b5ff,
	900 : #0aa9ff,
	A100 : #ffffff,
	A200 : #f5fbff,
	A400 : #c2e7ff,
	A700 : #a8deff,
	contrast: (50 : #ffffff,
		100 : #ffffff,
		200 : #ffffff,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #ffffff,
		A200 : #ffffff,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);

$md-miloto-secondary: (
	50 : #ffe7f0,
	100 : #ffc3d9,
	200 : #ff9cc0,
	300 : #ff74a6,
	400 : #ff5693,
	500 : #ff3880,
	600 : #ff3278,
	700 : #ff2b6d,
	800 : #ff2463,
	900 : #ff1750,
	A100 : #ffffff,
	A200 : #fffcfd,
	A400 : #ffc9d5,
	A700 : #ffb0c1,
	contrast: (50 : #ffffff,
		100 : #ffffff,
		200 : #ffffff,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #ffffff,
		A200 : #ffffff,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);

$my-primary: mat.define-palette($md-miloto-primary, 500);
$my-accent: mat.define-palette($md-miloto-secondary, 500);

$my-theme: mat.define-light-theme(
			(color: (
				primary: $my-primary,
				accent: $my-accent,
			),
			density: 0,
		));

// include the custom theme object into the angular material theme
@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);
@include mat.form-field-density(-5);